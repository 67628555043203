import React,  {useState, useEffect} from 'react'
import styled, {keyframes} from 'styled-components'
import css from '@styled-system/css'
import {Link} from 'gatsby'
import {useStore} from '../state'
import { useSpring, animated as a } from 'react-spring'
import Modal from './Modal';
import {isMobile} from '../utils'
import logo from '../imgs/logo.png'
import header_bg from '../imgs/header_bg.png'

const HumburgerWrapper = styled.div`

    cursor: pointer;

    > div {
        height: 2px;
        width: 24px;
        background: black;
        margin: 5px;
        transition: .3s ease;
    }
    > div:nth-child(2) {
        width: 16px;
    }

    &.clicked {
        > div:nth-child(2) {
            opacity: 0
        }   

        > div:nth-child(1) {
            transform: rotate(45deg)  translate(5px, 5px);
        }   

        > div:nth-child(3) {
            transform: rotate(-45deg) translate(5px, -5px);
        }   
    }


${css({
    display: ['block', 'none']
    
})}
`

const Humburger = ({clicked, toggleClicked}) => {
   
    return <><HumburgerWrapper onClick={() => toggleClicked(!clicked)} className={`${clicked?'clicked':''}`}>
        <div/>
        <div/>
        <div/>
    </HumburgerWrapper>
    </>
}


const slide = keyframes`

    from: {
        transform: translate3d(0, -100vh, 0);
    }
    to: {
        transform: translate3d(0, 0, 0);

    }
`
const toleft = keyframes`

    from {
        transform: translate3d(100vw, 0, 0);
    }

    to {
        transform: translate3d(0px, 0px, 0);
    }

`
const PortalWrapper = styled.div`

    position: fixed;
    top:62px;
    height: calc(100vh - 60px);
    width: 100vw;
    background: white;
    z-index: 4;
    animation: ${toleft} 0.2s ease;


`

const NavWrapper = styled.div`
.nav{
    
    text-align: right;

    display: flex;
    align-items:center;
    justify-content: flex-end;


    a {
        padding: 10px;
        margin: 10px;
        text-decoration: none;
        border: 2px solid white;
        &.active {
            border: 2px solid;
        }
    }
}

${css({
    '.nav': {
       flexDirection: ['column', 'row'] ,
       marginTop: ['20%', 0],
       fontSize: ['1.2rem', '1rem']
    }
})}
`

const HeaderWrapper = styled(a.div)`
display: flex;
.el {
    background-size: 57% 100%;
    height: 100px;
    position: relative;
    top: -1px;

    &.h_main {
        background: none;
        flex-grow: 0;
        position:relative;
        ${css({
            width: ['calc(100% - 20px)', 1040]
        })}   


        
        position: relative;

        .logo {
            position: absolute;
            overflow: hidden;
            height: inherit;

           img {
               position: absolute;
           }
        }
        
        
        .nav {
            position: absolute;
            right: 0px;
            bottom: 10px;
            
        }
        
    }

    ${HumburgerWrapper} {
        position: absolute;
        right: 10px;
        bottom: 17px;
    }
}



${css({
    '.center, .el': {
        height: [63, 101],
        backgroundPositionY: [74, 21],
        backgroundImage: ['none', `url(${header_bg})`],
        flexGrow: [0, 1],


    },
    
    '.el .nav': {
        display: ['none', 'flex']
    },
    '.el.h_main .logo': {
        width: ['100vw', '300px']

    },

    '.el.h_main .logo img': {
       'transform': [`scale(0.40)`, `scale(0.57)`],
       'top': [-52, -21],
       'left': [-1359,-1127]
    },
    '.center': {
        position: 'relative',
        'left': 300,
        'width': 'calc(100% - 300px)',
        overflow: 'hidden',
        img: {
            position: 'absolute',
            left: '-300px',
            'transform': `scale(0.57)`,
            'top': [-52, -21],
            'left': [-1359,-1427],
            zIndex: -1,
        }
        


    }
})}

`

export default ({pathname}) => {

    const { image_show } = useStore()

    const [humburgerOn, toggleHumburger] = useState(false)

    const [ {marginTop}, set] = useSpring(() => ({
        marginTop: 0
    }))

    if(image_show){
        set({marginTop: isMobile() ? -81 : -101})
    }else {
        set({marginTop: 0})
    }

    const menu =  <NavWrapper><div className="nav" onClick={() => toggleHumburger(false)} >
        <Link className={pathname.indexOf("/projects") === 0 ? 'active': ''} to="/projects/">Projects</Link>
        <Link className={pathname.indexOf("/wearenaice") === 0 ? 'active': ''} to="/wearenaice/">We are NAICE</Link>
        {humburgerOn ? <Link className={pathname.indexOf("/imprint") === 0 ? 'active': ''} to="/imprint/">Imprint</Link> : null}
    </div>
    </NavWrapper>



    const logonav = <div className="logo"><Link to="/"><img src={logo} /></Link></div>
    const centerimg = isMobile() ? null : <img src={logo}/>
    
    return <><HeaderWrapper style={{marginTop}}>
        <div className="el"/>
        <div className="el h_main">
            {logonav}
            <div className="center">
            <Humburger {...{clicked:humburgerOn, toggleClicked:toggleHumburger }}/>
            {menu}
            {centerimg}
            </div>
            
        </div>
        <div className="el"/>
    </HeaderWrapper>

    {humburgerOn ? <Modal><PortalWrapper>{menu}</PortalWrapper></Modal>: null}
    </>

}

