
export default {
    breakpoints: ['840px', '1200px'],

    colors: {
        bg: '#ffffff',
        text: '#333',
        
        modes: {
            dark: {
                bg: '#222',
                text: '#f1f1f1',
            }
        }
    }

}