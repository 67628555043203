import React from 'react';



const LinkedIn = () => <svg  height="16" viewBox="0 0 24 24" width="16" xmlns="http://www.w3.org/2000/svg"><path fill="currentcolor"  d="m23.994 24v-.001h.006v-8.802c0-4.306-.927-7.623-5.961-7.623-2.42 0-4.044 1.328-4.707 2.587h-.07v-2.185h-4.773v16.023h4.97v-7.934c0-2.089.396-4.109 2.983-4.109 2.549 0 2.587 2.384 2.587 4.243v7.801z"/><path fill="currentcolor" d="m.396 7.977h4.976v16.023h-4.976z"/><path fill="currentcolor" d="m2.882 0c-1.591 0-2.882 1.291-2.882 2.882s1.291 2.909 2.882 2.909 2.882-1.318 2.882-2.909c-.001-1.591-1.292-2.882-2.882-2.882z"/></svg>
const Facebook = () => <svg  xmlns="http://www.w3.org/2000/svg"  height="18"  width="18" viewBox="0 0 512 512"><g><path fill="currentcolor" d="M288,176v-64c0-17.664,14.336-32,32-32h32V0h-64c-53.024,0-96,42.976-96,96v80h-64v80h64v256h96V256h64l32-80H288z"/></g></svg>
const Insta = () => <svg version="1.1"xmlns="http://www.w3.org/2000/svg"
width="20" height="20" viewBox="0 0 169.063 169.063" >
<g>
<path fill="currentcolor"  d="M122.406,0H46.654C20.929,0,0,20.93,0,46.655v75.752c0,25.726,20.929,46.655,46.654,46.655h75.752
   c25.727,0,46.656-20.93,46.656-46.655V46.655C169.063,20.93,148.133,0,122.406,0z M154.063,122.407
   c0,17.455-14.201,31.655-31.656,31.655H46.654C29.2,154.063,15,139.862,15,122.407V46.655C15,29.201,29.2,15,46.654,15h75.752
   c17.455,0,31.656,14.201,31.656,31.655V122.407z"/>
<path fill="currentcolor"  d="M84.531,40.97c-24.021,0-43.563,19.542-43.563,43.563c0,24.02,19.542,43.561,43.563,43.561s43.563-19.541,43.563-43.561
   C128.094,60.512,108.552,40.97,84.531,40.97z M84.531,113.093c-15.749,0-28.563-12.812-28.563-28.561
   c0-15.75,12.813-28.563,28.563-28.563s28.563,12.813,28.563,28.563C113.094,100.281,100.28,113.093,84.531,113.093z"/>
<path fill="currentcolor"  d="M129.921,28.251c-2.89,0-5.729,1.17-7.77,3.22c-2.051,2.04-3.23,4.88-3.23,7.78c0,2.891,1.18,5.73,3.23,7.78
   c2.04,2.04,4.88,3.22,7.77,3.22c2.9,0,5.73-1.18,7.78-3.22c2.05-2.05,3.22-4.89,3.22-7.78c0-2.9-1.17-5.74-3.22-7.78
   C135.661,29.421,132.821,28.251,129.921,28.251z"/>
</g>
</svg>
 
export const FollowUs = () => {

    const linkedIn = <div className="el"><LinkedIn /></div>
    return <div className="follow_us">
                    <div className="label">Follow us</div>
                    <div className="icons">
                        <div className="el"><a href="https://www.instagram.com/naice_architecture/"><Insta /></a></div>
                        <div className="el"><a href="https://www.facebook.com/wilko299"><Facebook /></a></div>                        
                    </div>

                </div>
}