

import React, {useState, useEffect} from 'react'
import ReactDOM from 'react-dom'

const pdocumnet = typeof(document) !== "undefined" && document
const portal = pdocumnet && pdocumnet.getElementById('portal')

export default  ({children}) => {
    const [container] = useState(pdocumnet.createElement('div'))

    useEffect(() => {
        portal.appendChild(container)

        return () => portal.removeChild(container)
    }, [container])

    return ReactDOM.createPortal(children, container)
}